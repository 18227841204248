import { ref, watch, computed } from '@vue/composition-api';

// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import store from '@/store';

export default function useProfilesList() {
  // Use toast
  const toast = useToast();

  const refProfileListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    // {key: 'id', label: '#', sortable: true},
    { key: 'name', sortable: true },
    { key: 'creator', sortable: true },
    // { key: 'name', sortable: true },
    // { key: 'email', sortable: true },
    // { key: 'total', sortable: true, formatter: val => `$${val}` },
    { key: 'createdAt', sortable: true },
    { key: 'status', sortable: true },
    { key: 'actions' },
  ];
  const perPage = ref(10);
  const totalProfiles = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref('');
  const sortBy = ref('id');
  const isSortDirDesc = ref(true);

  const statusFilter = ref(null);
  const pathwayFilter = ref(null);
  const createAtStartFilter = ref(null);
  const createAtEndFilter = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refProfileListTable.value ? refProfileListTable.value.localItems.length : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalProfiles.value,
    };
  });

  const refetchData = () => {
    refProfileListTable.value.refresh();
  };

  watch([currentPage, perPage, searchQuery, createAtStartFilter, createAtEndFilter, pathwayFilter, statusFilter], () => {
    refetchData();
  });

  const fetchProfiles = (ctx, callback) => {
    const sbv = sortBy.value === 'id' ? '_id' : sortBy.value;
    const sb = `${sbv}:${isSortDirDesc.value ? 'desc' : 'asc'}`;

    const pl = {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sortBy: sb,
      populate: 'creator profiles',
    };

    if (statusFilter.value) {
      pl.status = statusFilter.value;
    }
    if (pathwayFilter.value) {
      pl.programName = pathwayFilter.value;
    }
    if (createAtStartFilter.value) {
      pl.createAtStart = createAtStartFilter.value;
    }
    if (createAtEndFilter.value) {
      pl.createAtEnd = createAtEndFilter.value;
    }

    store
      .dispatch('app-placement/fetchProfiles', pl)
      .then((response) => {
        const { results, totalResults } = response.data;

        console.log(results);

        callback(results);
        totalProfiles.value = totalResults;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching profiles' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveProfileStatusVariantAndIcon = (status) => {
    if (status === 'Partial Payment') return { variant: 'warning', icon: 'PieChartIcon' };
    if (status === 'Paid') return { variant: 'success', icon: 'CheckCircleIcon' };
    if (status === 'Downloaded') return { variant: 'info', icon: 'ArrowDownCircleIcon' };
    if (status === 'Draft') return { variant: 'primary', icon: 'SaveIcon' };
    if (status === 'Sent') return { variant: 'secondary', icon: 'SendIcon' };
    if (status === 'Past Due') return { variant: 'danger', icon: 'InfoIcon' };
    return { variant: 'secondary', icon: 'XIcon' };
  };

  const resolveProfileStatusVariant = (status) => {
    if (status === 'archived') return 'secondary';
    if (status === 'locked') return 'danger';
    if (status === 'expired') return 'warning';
    if (status === 'active') return 'success';
    if (status === 'created') return 'secondary';
    return 'primary';
  };

  const resolveClientAvatarVariant = (status) => {
    if (status === 'Partial Payment') return 'primary';
    if (status === 'Paid') return 'danger';
    if (status === 'Downloaded') return 'secondary';
    if (status === 'Draft') return 'warning';
    if (status === 'Sent') return 'info';
    if (status === 'Past Due') return 'success';
    return 'primary';
  };

  const statusOptions = [
    { label: 'Created', value: 'created' },
    { label: 'Active', value: 'active' },
    { label: 'Expired', value: 'expired' },
    { label: 'Locked', value: 'locked' },
    { label: 'Archived', value: 'archived' },
  ];
  const pathwayOptions = [
    { label: 'CompTIA Security+', value: 'CompTIA Security+' },
    { label: 'DevOps Pathway', value: 'DevOps Pathway' },
  ];

  const clearanceOptions = [
    { label: 'None', value: 'none' },
    { label: 'Public Trust', value: 'public_trust' },
    { label: 'Confidential', value: 'confidential' },
    { label: 'Secret', value: 'secret' },
    { label: 'Top Secret', value: 'top_secret' },
    { label: 'TS + SCI', value: 'ts_sci' },
    { label: 'TS + POLY', value: 'ts_poly' },
    { label: 'TS + POLY + SCI', value: 'ts_poly_sci' },
  ];

  const relocatableOptions = [
    { label: 'No', value: 'no' },
    { label: 'Conditionally', value: 'conditionally' },
    { label: 'Yes', value: 'yes' },
  ];

  const remoteOptions = [
    { label: 'No', value: 'no' },
    { label: 'Conditionally', value: 'conditionally' },
    { label: 'Yes', value: 'yes' },
  ];

  const veteranOptions = [
    { label: 'No', value: false },
    { label: 'Yes', value: true },
  ];

  const visibilityOptions = [
    { label: 'Internal', value: 'internal' },
    { label: 'Recruiter', value: 'recruiter' },
    { label: 'Student', value: 'student' },
  ];

  return {
    fetchProfiles,
    tableColumns,
    perPage,
    currentPage,
    totalProfiles,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refProfileListTable,

    statusFilter,
    pathwayFilter,
    createAtStartFilter,
    createAtEndFilter,

    resolveProfileStatusVariantAndIcon,
    resolveProfileStatusVariant,
    resolveClientAvatarVariant,

    refetchData,
    statusOptions,
    pathwayOptions,

    clearanceOptions,
    relocatableOptions,
    remoteOptions,
    veteranOptions,
    visibilityOptions,
  };
}
